import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom';

import NavbarBlack from '../../components/navbar-black'
import Footer from '../../components/footer'
import Switcher from '../../components/switcher';
import {IoMdClose} from "../../assets/icons/vander"
import {        
    useGlobalState,
  } from '../../store/indexStore'

/* global BigInt */
export default function ItemDetail() {

    
    const [nft] = useGlobalState('nft')    
    const [collection] = useGlobalState('collection')
    const [artists] = useGlobalState('artists')
    try {
        // Código que puede generar un error
        console.log(collection.name)

    } catch (error) {
        window.location.href = '/';        // Manejo del error
    }
    let nftAuthor = artists.filter(miembro => miembro.idArtist.toNumber() === collection?.authorId?.toNumber())

    var a = new Date(nft?.Timestamp * 1000);
    var months = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];
    var year = a.getFullYear();
    var month = months[a.getMonth()];
    var date = a.getDate();
    var hour = a.getHours();
    var min = a.getMinutes();
    var sec = a.getSeconds();
    var formattedTime = date + ' ' + month + ' ' + year + ' ' + hour + ':' + min + ':' + sec ;

    
    // Will display time in 10:30:23 format


    useEffect(() => {
        document.documentElement.classList.add('light');
    }, []);
    return (
        <>
            <NavbarBlack />
            <section className="relative pt-28 md:pb-24 pb-16 mt-24 ">
                <div className="small_xxls_container">

                    <div className="grid lg:grid-cols-12 md:grid-cols-2 grid-cols-1 gap-[30px]">
                        <div className="lg:col-span-5 md:order-1 order-2">
                            
                            <img src={nft?.tokenURI ? nft?.tokenURI : nft?.tokenURI} className="rounded-md shadow w-full dark:shadow-gray-700" alt="" />

                            <div className="rounded-md mt-[30px] p-6">
                                <div>
                                    <span className="font-medium font-inter text-green block mb-1">Contract Address</span>
                                    <Link to={"https://arbiscan.io/address/"+process.env.REACT_APP_ADDR_NFT} className="text-[16px] font-inter text-black block">{process.env.REACT_APP_ADDR_NFT.slice(0,10)}....</Link>
                                </div>

                                <div className="mt-4">
                                    <span className="font-medium text-green block mb-1 font-inter">Owner</span>
                                    <Link to={"https://arbiscan.io/address/"+nft?.owner.toString()} className="text-[16px] font-inter text-black block">{nft?.owner.toString().slice(0,10)}....</Link>
                                </div>
                            
                                <div className="mt-4">
                                    <span className="font-medium text-green block mb-1 font-inter">Token ID</span>
                                    <span className="text-[16px] block font-inter text-black">{nft?.tokenId.toString()}</span>
                                </div>

                                <div className="mt-4">
                                    <span className="font-medium text-green block mb-1 font-inter">Creation</span>
                                    <span className="text-[16px] block font-inter text-black">{formattedTime}</span>
                                </div>

                            </div>
                        </div>

                        <div className="lg:col-span-7 lg:ms-8 md:order-2 order-1">
                            
                            <div className="flex p-6 mt-6">
                                <div className="relative inline-block">
                                    <img src={collection?.collectionURI ? collection?.collectionURI : collection?.collectionURI} className="w-16  rounded-md" alt="" />
                                    <i className="mdi mdi-check-circle text-emerald-600 text-lg absolute -top-2 -end-2"></i>
                                </div>

                                <div className="ms-3">
                                    <span className="font-inter text-black font-bold block mt-2">From this collection: <span className="text-green">@{collection?.name ? collection?.name : "@FunnyGuy"}</span></span>
                                </div>
                            </div>     

                            <span className="md:text-smm sm:text-smm lg:text-smm xl:text-smm text-black block mt-6">Gaudí Artoy! Legacy</span>
                            <span className="md:text-smm sm:text-smm lg:text-smm xl:text-smm text-black block lg:mt-8 mt-6">Series</span>

                            {nft?.isPhProduct ? (

                                <div className="mt-8 inline-flex items-center">
                                    <span className="mdi mdi-check-circle font-inter text-emerald-600"></span>
                                    <p className="text-black  ml-4">This product has a physical product associated</p>
                                </div>
                            ) :
                                <div className="mt-8 inline-flex items-center">
                                    <span className="mdi mdi-check-circle font-inter text-red-600"></span>
                                    <p className="text-black font-inter">This product not has a physical product associated</p>
                                </div>

                            }

                            <p className="text-black mt-8 font-inter">Name: {nft?.name}</p>
                            <p className="text-black mt-4 font-inter">Description: {nft?.description}</p>

                            <div className="flex p-6 mt-6">
                                
                                <Link to={`/creator-profile/${nftAuthor[0]?.idArtist}`}>
                                    <img src={nftAuthor[0]?.avatar} className="md:w-16 w-16 rounded-xl" alt="" />
                                </Link>

                               
                                
                                <div className="grid grid-rows-2 items-center">

                                    <div className="ms-3">
                                        <span className="font-bold text-black font-inter">Author Artoy</span>
                                    </div>
                                    <div className="ms-3">
                                        <Link to={`/creator-profile/${nftAuthor[0]?.idArtist}`} 
                                            className="text-green font-inter">@{nftAuthor[0]?.name}
                                        </Link>
                                    </div>
                                </div>
                            </div>     

                            
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    )
}
