import React, { useEffect } from 'react'
import { Link } from 'react-router-dom';
import {MdKeyboardArrowLeft, MdKeyboardArrowRight, MdOutlineArrowForward} from "../assets/icons/vander"
import {        
    useGlobalState,
    setGlobalState,
  } from '../store/indexStore'

import imageBlack from '../assets/images/GKA-black.png';

import { useParams } from 'react-router-dom';
import { getAllCollections, isWallectConnected,getAllNFTs } from '../Blockchain.Services'

export default function DiscoverItems({title,pagination, allData}) {
    const [collection,setCollection] = useGlobalState('collection')
    const [owner] = useGlobalState('connectedAccount')
    const [mpOwner] = useGlobalState('owner')
    const [list] = useGlobalState('list')
    const [artists] = useGlobalState('artists')
    let nftAuthor

    let nftsAux

    nftAuthor = artists.filter(miembro => miembro.idArtist.toNumber() === collection?.authorId?.toNumber())
    const [collections] = useGlobalState('collections')
    const [nfts, setNfts] = useGlobalState('nfts')
    const params = useParams();
    let creater
    const id = params.id

    try{
        nftsAux = nfts.slice(1);
       
        creater = collections.find((creatorr) => creatorr.idCollectionInt === (id));
    }catch{}
    

    const loadItemData = async () => {
        try{
            creater = collections.find((creatorr) => creatorr.idCollectionInt === (id));
            const updatedNfts = await getAllNFTs(creater?.idCollection);
            setNfts(updatedNfts);
            setCollection(creater);

            nftsAux = nfts.slice(1);
        }catch{}
    
      };
    
      useEffect(() => {
        document.documentElement.classList.add('light');
        loadItemData();
      }, [collections]);

    
    const handleSubmit = async (nft, list) => {
        setGlobalState('nft', nft)
        setGlobalState('nList', list)
    }

    return (
        <>
            <section className="relative md:pb-24 pb-16">
                <div className="small_xxls_container">

                    <div className="grid lg:grid-cols-12 md:grid-cols-2 grid-cols-1 gap-[30px]">
                        <div className="lg:col-span-5 md:order-1 order-2">
                            <div className="container px-36">
                                    
                                <div className="group border-gray-100 dark:border-gray-800 hover:shadow-gray-700 transition-all duration-500 hover:-mt-2 h-fit">

                                    {nfts?.length > 0 ? (
                                        <div className="relative overflow-hidden justify-center">
                                            <Link to={`/item-detail`}>
                                                <img src={nfts[0].tokenURI} onClick={() => handleSubmit(nfts[0], list[0])} className="mt-3 mb-3 justify-center w-full group-hover:opacity-10" alt="" />
                                            </Link>
                                            <div className="px-4 mt-36 mb-3">
                                                <div className="flex items-center">
                                                        <div className="border-x w-8 h-0.5 mt-3"></div>
                                                        <Link to={"/item-detail"}  onClick={() => handleSubmit(nfts[0], list[0])} className="mt-3 font-semibold block text-[13px] text-orange">
                                                            <span className="border-l border-black pl-4 ml-4">Have a look</span>
                                                        </Link>
                                                    </div>
                                               </div>
                                            </div>
                                    ) : (
                                        <div className="flex justify-center items-center mt-3 mb-3">
                                            <div className="spinner"></div>
                                        </div>
                                    )}





                                </div>

                                <div className="grid xl:grid-cols-3 lg:grid-cols-3 md:grid-cols-3 grid-cols-2 mt-10 mb-24 gap-[30px]">

                                    {nftsAux?.map((item, index) => (
                                        <div key={index} className="group border-gray-100 dark:border-gray-800 hover:shadow-gray-700 transition-all duration-500 hover:-mt-2 h-fit">
                                            
                                                <div className="opacity-100 transition-all duration-500">

                                                    <div className="overflow-hidden">
                                                        
                                                        <Link to={`/item-detail`}>
                                                            <img src={item.tokenURI} onClick={() => handleSubmit(item, list[index])} className="w-full justify-center group-hover:opacity-10" alt="" />
                                                        </Link>

                                                        <div className="mt-36 mb-3">
                                                            <div className="items-left">
                                                                    <Link to={"/item-detail"}  onClick={() => handleSubmit(item, list[index])} className="mt-3 text-orange">
                                                                        <span className="md:text-[12px] text-[12px] border-l border-black pl-4 ml-4">Have a look</span>
                                                                    </Link>
                                                                </div>
                                                        </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))}

                                </div>
                            
                            </div>
                        </div>
                    

                    <div className="lg:col-span-5 md:order-2 order-1">
                            {nfts?.length > 0 ? ( 
                                <div className="flex p-6 mt-6">
                                    
                                    <div className="relative inline-block">
                                        <img src={collection?.collectionURI ? collection?.collectionURI : imageBlack} className="md:w-16 w-16 rounded-md" alt="" />
                                        <i className="mdi mdi-check-circle text-emerald-600 text-lg absolute -top-2 -end-2"></i>
                                    </div>

                                    <div className="ms-3">
                                        <span className="font-inter text-black font-bold block mt-2">Collection: <span className="text-green">@{collection?.name ? collection?.name :  " iD: " + id + " not found"}</span></span>
                                    </div>
                                    
                                </div>     
                            ) : (
                                null
                            )}

                            <span className="md:text-smm sm:text-smm lg:text-smm xl:text-smm text-black block mt-6">Gaudí Artoy! Legacy</span>
                            <span className="md:text-smm sm:text-smm lg:text-smm xl:text-smm text-black block lg:mt-8 mt-6">Series</span>

                            
                            <p className="text-black mt-8 font-inter">Name: {collection?.name? collection?.name : " iD: " + id + " not found"}</p>
                            <p className="text-black mt-4 font-inter">Description: {collection?.description?collection?.description  : " iD: " + id + " not found"}</p>

                            <div className="flex p-6 mt-6">

                                {nfts?.length > 0 ? (             
                                    <Link to={`/creator-profile/${nftAuthor[0]?.idArtist}`}>
                                        <img src={nftAuthor[0]?.avatar?nftAuthor[0]?.avatar : imageBlack} className="md:w-16 w-16 rounded-xl" alt="" />
                                    </Link>
                                ) : (
                                    null
                                )}

                                <div className="grid grid-rows-2 items-center">

                                    <div className="ms-3">
                                        <span className="font-bold text-black font-inter">Collection author</span>
                                    </div>

                                    <div className="ms-3">
                                        <Link to={`/creator-profile/${nftAuthor[0]?.idArtist}`} 
                                            className="text-green font-inter">@{nftAuthor[0]?.name ? nftAuthor[0]?.name : " iD: " + id + " not found"}
                                        </Link>

                                    </div>
                                    
                                </div>
                        
                            </div>     

                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
/*
 <div className="px-4 mt-36 mb-3">

                                                            <Link to={`/item-detail`} 
                                                                onClick={() => handleSubmit(item, list[index])} 
                                                                className="md:text-[14px] text-[12px] font-luter text-black">Product: {item.tokenId.toString()?item.tokenId.toString():"Collection iD: " + id + " not found"}
                                                            </Link>
                                                        </div>
                                                        <div className="px-4 mt-36 mb-3">
                                                            <Link to={`/item-detail`} 
                                                                onClick={() => handleSubmit(item, list[index])} 
                                                                className="md:text-[12px] text-[12px] font-luter text-black">{item.name}
                                                            </Link>

                                                        </div>
*/

/*
 <div className="absolute px-4 mt-36 mb-3 -bottom-20 group-hover:bottom-1/8 group-hover:translate-y-1/2 start-0 end-0 mx-auto text-left transition-all duration-500">

                                        <Link to={`/item-detail`} 
                                            onClick={() => handleSubmit(item, list[index])} 
                                            className="md:text-[16px] text-[16px] font-luter text-black">{item.name}
                                        </Link>
                                
                                
                                    </div>
                                    
                                    <div className="absolute px-4 -bottom-20 group-hover:bottom-1/10 group-hover:translate-y-1/2 start-0 end-0 mx-auto text-left transition-all duration-500">

                                        <Link to={`/item-detail`} 
                                            onClick={() => handleSubmit(item, list[index])} 
                                            className="md:text-[14px] text-[14px] font-luter text-black">ID {item.tokenId.toString()}
                                        </Link>
                                    </div>
*/


/*
import React from 'react'
import { Link } from 'react-router-dom';
import {MdKeyboardArrowLeft, MdKeyboardArrowRight, MdOutlineArrowForward} from "../assets/icons/vander"
import {        
    useGlobalState,
    setGlobalState,
  } from '../store/indexStore'


export default function DiscoverItems({title,pagination, allData}) {
    const [nfts] = useGlobalState('nfts')
    const [collection] = useGlobalState('collection')
    const [owner] = useGlobalState('connectedAccount')
    const [mpOwner] = useGlobalState('owner')
    const [list] = useGlobalState('list')
    const nftsAux = nfts.slice(1);
    console.log("mpOwner")
    console.log(mpOwner)
    console.log("collection")
    console.log(collection)
    const handleSubmit = async (nft, list) => {
        setGlobalState('nft', nft)
        setGlobalState('nList', list)
    }

    return (
        <>
        
            <div className="container px-36">
                    <div className="absolute -bottom-20 justify-center group-hover:bottom-1/2 group-hover:translate-y-1/2 start-0 end-0 mx-auto text-center transition-all duration-500">
                        <Link to={`/upload-nft`} className="btn btn-sm rounded-full bg-violet-600 hover:bg-violet-700 border-violet-600 hover:border-violet-700 text-white">Mint NFT</Link>
                    </div>
                    
                    <div className="small_container">

                    <div className="group border-gray-100 dark:border-gray-800 hover:shadow-gray-700 transition-all duration-500 hover:-mt-2 h-fit">
                    
                        {nfts.length > 0 ? (

                            <div className="relative overflow-hidden justify-center">
                                <img src={nfts[0].tokenURI} className="px-4 mt-3 mb-3 justify-center w-full group-hover:opacity-10" alt="" />
                                <div className="px-4 mt-36 mb-3">
                                    <Link to={`/item-detail`} 
                                        onClick={() => handleSubmit(nfts[0], list[0])} 
                                        className="md:text-[26px] text-[14px] font-luter ml-4 text-black">Main collection product
                                    </Link>
                                </div>

                                    
                                <div className="px-4 mt-36 mb-3">
                                        <Link to={`/item-detail`} 
                                            onClick={() => handleSubmit(nfts[0], list[0])} 
                                            className="md:text-[16px] text-[14px] font-luter ml-4 text-black">{nfts[0].name}
                                        </Link>
                                    </div>
                                        
                                <div className="px-4 mt-36 mb-3">
                
                                    <Link to={`/item-detail`} 
                                        onClick={() => handleSubmit(nfts[0], list[0])} 
                                        className="md:text-[14px] text-[12px] font-luter ml-4 text-black">ID {nfts[0].tokenId.toString()}
                                    </Link>
                                </div>
                            </div>
                        ):null}
                
                    </div>
                </div>
                

                <div className="grid xl:grid-cols-3 lg:grid-cols-3 md:grid-cols-3 grid-cols-2 mt-24 mb-24 gap-[30px]">

                    {nftsAux.map((item, index) => (
                        <div key={index} className="group border-gray-100 dark:border-gray-800 hover:shadow-gray-700 transition-all duration-500 hover:-mt-2 h-fit">
                            
                            <div className='small_xs_container mt-3 mb-3'>
                            <div className="opacity-100 transition-all duration-500">

                                <div className="relative overflow-hidden">
                                    
                                    <img src={item.tokenURI} className="w-full justify-center group-hover:opacity-10" alt="" />
                                    <div className="px-4 mt-36 mb-3">
                                        <Link to={`/item-detail`} 
                                            onClick={() => handleSubmit(item, list[index])} 
                                            className="md:text-[16px] text-[12px] font-luter text-black">{item.name}
                                        </Link>

                                        </div>

                                    <div className="px-4 mt-36 mb-3">

                                        <Link to={`/item-detail`} 
                                            onClick={() => handleSubmit(item, list[index])} 
                                            className="md:text-[14px] text-[12px] font-luter text-black">ID {item.tokenId.toString()}
                                        </Link>
                                    </div>
                                </div>
                                </div>
                                </div>
                        </div>
                    ))}

                </div>
               
            </div>
        </>
    )
}
*/

/*
 <div className="absolute px-4 mt-36 mb-3 -bottom-20 group-hover:bottom-1/8 group-hover:translate-y-1/2 start-0 end-0 mx-auto text-left transition-all duration-500">

                                        <Link to={`/item-detail`} 
                                            onClick={() => handleSubmit(item, list[index])} 
                                            className="md:text-[16px] text-[16px] font-luter text-black">{item.name}
                                        </Link>
                                
                                
                                    </div>
                                    
                                    <div className="absolute px-4 -bottom-20 group-hover:bottom-1/10 group-hover:translate-y-1/2 start-0 end-0 mx-auto text-left transition-all duration-500">

                                        <Link to={`/item-detail`} 
                                            onClick={() => handleSubmit(item, list[index])} 
                                            className="md:text-[14px] text-[14px] font-luter text-black">ID {item.tokenId.toString()}
                                        </Link>
                                    </div>
*/
