import React from 'react'
import { Link } from 'react-router-dom';
import {  FaRegEnvelope,FaYoutube, LiaFacebookF, AiOutlineInstagram, AiOutlineTwitter } from "../assets/icons/vander"

export default function Footer() {
    return (
        <footer className="footer bg-cover bg-dark-footer relative text-gray-200 dark:text-gray-200 mt-24">
            <div className="py-[30px] px-0 border-t border-gray-800 dark:border-gray-700">
                <div className="container text-center">
                    <div className="grid md:grid-cols-2 items-center gap-6">
                        <div className="md:text-start text-center">
                            <p className="mb-0 text-gray-300">© {(new Date().getFullYear())}{" "} All rights reserved © Gaudi Knowledge Association 2023 </p>
                        </div>

                        <ul className="list-none md:text-end text-center">
                            <li className="inline space-x-1"><Link to="https://www.youtube.com/channel/UCwthOAR8KdejD_Is6LzA6pw" target="_blank" className="btn btn-icon btn-sm border border-gray-800 rounded-md hover:border-violet-600 dark:hover:border-violet-600 hover:bg-violet-600 dark:hover:bg-violet-600"><FaYoutube className='text-base'/></Link></li>
                            <li className="inline space-x-1"><Link to="https://www.facebook.com/GaudiKnowledge/" target="_blank" className="btn btn-icon btn-sm border border-gray-800 rounded-md hover:border-violet-600 dark:hover:border-violet-600 hover:bg-violet-600 dark:hover:bg-violet-600"><LiaFacebookF className='text-base'/></Link></li>
                            <li className="inline space-x-1"><Link to="https://www.instagram.com/gaudiknowledge/" target="_blank" className="btn btn-icon btn-sm border border-gray-800 rounded-md hover:border-violet-600 dark:hover:border-violet-600 hover:bg-violet-600 dark:hover:bg-violet-600"><AiOutlineInstagram className='text-base'/></Link></li>
                            <li className="inline space-x-1"><Link to="https://twitter.com/gaudiknowledge" target="_blank" className="btn btn-icon btn-sm border border-gray-800 rounded-md hover:border-violet-600 dark:hover:border-violet-600 hover:bg-violet-600 dark:hover:bg-violet-600"><AiOutlineTwitter className='text-base'/></Link></li>
                            <li className="inline space-x-1"><Link to="mailto:sofya@gaudiknowledge.org" className="btn btn-icon btn-sm border border-gray-800 rounded-md hover:border-violet-600 dark:hover:border-violet-600 hover:bg-violet-600 dark:hover:bg-violet-600"><FaRegEnvelope className="align-middle"/></Link></li>
                        </ul>
                    </div>
                </div>
            </div>
        </footer>
    )
}
