import React, { useEffect, useState } from 'react'
import { getAllCollectionsAndNFts } from '../Blockchain.Services'
import { setGlobalState,  setAlert } from '../store/indexStore'

import logo_icon_28 from '../assets/images/GKA-white.png';
import logo_dark from '../assets/images/GKA-black.png';
import logo_white from '../assets/images/GKA-white.png';
import MetaMask_Fox from '../assets/images/MetaMask_Fox.png';
import { Link } from "react-router-dom";
const { ethereum } = window

export default function Navbar() {
    const [isDropdown, openDropdown] = useState(true);
    const [isOpen, setMenu] = useState(true);   

    useEffect(() => {
        activateMenu();
    });
    
    const handleSubmit = async () => {
        
        try {
            await getAllCollectionsAndNFts();     
        } catch (error) {
            setAlert('Error loading NFTs...', 'red')
        }
    }

    const handleLoasCollArte = async () => {
        
        try {
            await getAllCollectionsAndNFts();     
        } catch (error) {
            setAlert('Error loading NFTs...', 'red')
        }
    }

    window.addEventListener("scroll", windowScroll);
    function windowScroll() {
        const navbar = document.getElementById("topnav");
        if (
            document.body.scrollTop >= 50 ||
            document.documentElement.scrollTop >= 50
        ) {
            if (navbar !== null) {
                navbar?.classList.add("nav-sticky");
            }
        } else {
            if (navbar !== null) {
                navbar?.classList.remove("nav-sticky");
            }
        }

        const mybutton = document.getElementById("back-to-top");
        if (mybutton !== null) {
            if (document.body.scrollTop > 500 || document.documentElement.scrollTop > 500) {
                mybutton.classList.add("flex");
                mybutton.classList.remove("hidden");
            } else {
                mybutton.classList.add("hidden");
                mybutton.classList.remove("flex");
            }
        }
    }

    const toggleMenu = () => {
        setMenu(!isOpen)
        if (document.getElementById("navigation")) {
            const anchorArray = Array.from(document.getElementById("navigation").getElementsByTagName("a"));
            anchorArray.forEach(element => {
                element.addEventListener('click', (elem) => {
                    const target = elem.target.getAttribute("href")
                    if (target !== "") {
                        if (elem.target.nextElementSibling) {
                            var submenu = elem.target.nextElementSibling.nextElementSibling;
                            submenu.classList.toggle('open');
                        }
                    }
                })
            });
        }
    }

    const getClosest = (elem, selector) => {

        // Element.matches() polyfill
        if (!Element.prototype.matches) {
            Element.prototype.matches =
                Element.prototype.matchesSelector ||
                Element.prototype.mozMatchesSelector ||
                Element.prototype.msMatchesSelector ||
                Element.prototype.oMatchesSelector ||
                Element.prototype.webkitMatchesSelector ||
                function (s) {
                    var matches = (this.document || this.ownerDocument).querySelectorAll(s),
                        i = matches.length;
                    while (--i >= 0 && matches.item(i) !== this) { }
                    return i > -1;
                };
        }

        // Get the closest matching element
        for (; elem && elem !== document; elem = elem.parentNode) {
            if (elem.matches(selector)) return elem;
        }
        return null;

    };
    const activateMenu = () => {
        var menuItems = document.getElementsByClassName("sub-menu-item");
        if (menuItems) {

            var matchingMenuItem = null;
            for (var idx = 0; idx < menuItems.length; idx++) {
                if (menuItems[idx].href === window.location.href) {
                    matchingMenuItem = menuItems[idx];
                }
            }

            if (matchingMenuItem) {
                matchingMenuItem.classList.add('active');


                var immediateParent = getClosest(matchingMenuItem, 'li');

                if (immediateParent) {
                    immediateParent.classList.add('active');
                }

                var parent = getClosest(immediateParent, '.child-menu-item');
                if (parent) {
                    parent.classList.add('active');
                }

                var parent = getClosest(parent || immediateParent, '.parent-menu-item');

                if (parent) {
                    parent.classList.add('active');

                    var parentMenuitem = parent.querySelector('.menu-item');
                    if (parentMenuitem) {
                        parentMenuitem.classList.add('active');
                    }

                    var parentOfParent = getClosest(parent, '.parent-parent-menu-item');
                    if (parentOfParent) {
                        parentOfParent.classList.add('active');
                    }
                } else {
                    var parentOfParent = getClosest(matchingMenuItem, '.parent-parent-menu-item');
                    if (parentOfParent) {
                        parentOfParent.classList.add('active');
                    }
                }
            }
        }
    }

    const metamask = async () => {
        try {
            //Basic Actions Section
            const onboardButton = document.getElementById('connectWallet')
            //   metamask modal
            const modal = document.getElementById('modal-metamask')
            const closeModalBtn = document.getElementById('close-modal')
            //   wallet address
            const myPublicAddress = document.getElementById('myPublicAddress')

            //Created check function to see if the MetaMask extension is installed
            const isMetaMaskInstalled = () => {
                //Have to check the ethereum binding on the window object to see if it's installed
                const { ethereum } = window
                return Boolean(ethereum && ethereum.isMetaMask)
            }



            const onClickConnect = async () => {
                if (!isMetaMaskInstalled()) {
                    //meta mask not installed
                    modal.classList.add('show')
                    modal.style.display = 'block'
                    return
                }
                try {
                    // eslint-disable-next-line no-undef
                    await ethereum.request({ method: 'eth_requestAccounts' })
                    // eslint-disable-next-line no-undef
                    const accounts = await ethereum.request({ method: 'eth_accounts' })
                    if (ethereum.chainId !== '0xa4b1' || ethereum.chainId !== '0x66eed'){
                        try {
                          await ethereum.request({
                            method: 'wallet_switchEthereumChain',
                            params: [{ chainId: '0x66eed' }],
                          });
                        } catch (switchError) {
                        // This error code indicates that the chain has not been added to MetaMask.
                          if (switchError.code === 4902) {
                            // Do something
                          }
                        }
                      }

                    setGlobalState('connectedAccount', accounts[0])


                    // eslint-disable-next-line no-undef


                            
                } catch (error) {
                    console.error(error)
                }
            }


            const closeModal = () => {
                modal.classList.remove('show')
                modal.style.display = 'none'
            }

            if (isMetaMaskInstalled()) {
                // eslint-disable-next-line no-undef
                const accounts = await ethereum.request({ method: 'eth_accounts' })
                if (!!accounts[0]) {
                    myPublicAddress.innerHTML =
                        accounts[0].split('').slice(0, 6).join('') +
                        '...' +
                        accounts[0]
                            .split('')
                            .slice(accounts[0].length - 7, accounts[0].length)
                            .join('')
                }
            }

            onboardButton.addEventListener('click', onClickConnect)
            closeModalBtn.addEventListener('click', closeModal)
        } catch (error) { }
    }

    return (
        <>
            <nav id="topnav" className="defaultscroll is-sticky">
                <div className="container py-4 max-w-screen-xl justify-between mx-auto">

                    {/* <!-- Logo container--> */}
                    <Link className="logo ps-0" to="/">
                        <div className="sm:block hidden">
                            <img src={logo_white} className="inline-block dark:hidden h-10" alt="" />
                        </div>
                    </Link>

                    <div className="menu-extras">
                        <div className="menu-item">
                            {/* <!-- Mobile menu toggle--> */}
                            <Link to="#" className="navbar-toggle" id="isToggle" onClick={toggleMenu}>
                                <div className="lines">
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                </div>
                            </Link>
                        </div>
                    </div>

                    {/* <!--Login button Start--> */}
        
                    
                                    

                    <div id="navigation" className={`${isOpen === true ? 'hidden' : 'block'}`}>

                        <ul className="navigation-menu justify-end">

                            <li className="has-submenu parent-parent-menu-item">
                                <Link to="/index" class="text-white font-inter text-[20px]" onClick={() => handleSubmit()}>Home</Link>
                            </li>

                            <li className="has-submenu parent-parent-menu-item">
                                <Link to="https://www.thegaudiacademia.com/about/" class="text-white font-inter text-[20px]">About us</Link>
                            </li>

                            <li className="has-submenu parent-parent-menu-item">
                                <Link to="https://www.thegaudiacademia.com/contact/" class="text-white font-inter text-[20px]">Contact us</Link>
                            </li>

                           
                           
                        </ul>
                    </div>
                </div>
            </nav>

        </>
    )
}

/*
<ul className="buy-button list-none mb-0">
                        <li className="inline-block ps-1 mb-0">
                            <Link to="#" onClick={metamask} id="connectWallet" 
                                className="btn-border btn-icon rounded-full border-violet-600">
                                <img src={MetaMask_Fox} className="inline-block" alt="" />
                            </Link>
                        </li>
                    </ul>
<ul className="buy-button list-none mb-0">
                        <li className="inline-block ps-1 mb-0">
                            <Link to="#" onClick={metamask} id="connectWallet" 
                                className="btn-border btn-icon rounded-full border-violet-600">
                                <img src={MetaMask_Fox} className="inline-block" alt="" />
                            </Link>
                        </li>
                    </ul>
                                    

                    <div id="navigation" className={`${isOpen === true ? 'hidden' : 'block'}`}>

                        <ul className="navigation-menu justify-end">

                            <li className="has-submenu parent-parent-menu-item">
                                <Link to="/index" class="text-white font-inter text-[20px]" onClick={() => handleSubmit()}>Home</Link>
                            </li>

                            <li className="has-submenu parent-parent-menu-item">
                                <Link to="/upload-work" class="text-white font-inter text-[20px]">Upload Work</Link>
                            </li>

                            <li className="has-submenu parent-parent-menu-item">
                                <Link to="/become-creator" class="text-white font-inter text-[20px]">Create artist</Link>
                            </li>

                            <li className="has-submenu parent-parent-menu-item">
                                <Link to="/become-creator" class="text-white font-inter text-[20px]"></Link>
                            </li>
                           
                        </ul>
                    </div>
                    
                    */

