import React, { useEffect } from 'react'
import Navbar from '../../components/navbar'
import Footer from '../../components/footer'
import Select from 'react-select'
import DiscoverItems from '../../components/discover-items';    
import Switcher from '../../components/switcher';
import {LuClock, LuSearch, AiOutlineAppstore, AiOutlineStar} from "../../assets/icons/vander"
import {        
    useGlobalState,
    setGlobalState,
  } from '../../store/indexStore'

  import { Link, useParams } from 'react-router-dom';
  import { getAllCollections, isWallectConnected,getAllNFTs } from '../../Blockchain.Services'


const options = [
    { value: 'Art', label: 'Art' },
    { value: 'Domain', label: 'Domain Names' },
    { value: 'Music', label: 'Music' },
    { value: 'Sports', label: 'Sports' },
    { value: 'Trading', label: 'Trading Cards' },
    { value: 'Virtual', label: 'Virtual World' },
];

const buyOptions = [
    { value: 'now', label: 'Buy Now' },
    { value: 'auctions', label: 'Auctions' },
    { value: 'offers', label: 'Offers' }
]
const rateOptions = [
    { value: 'top', label: 'Top Rated' },
    { value: 'low', label: 'Lower Rated' }
]
export default function ExploreOne() {
    const [collection] = useGlobalState('collection')
    const [collections] = useGlobalState('collections')
    const [nfts, setNfts] = useGlobalState('nfts')
    const params = useParams();

    const id = params.id
    let creater = collections.find((creatorr) => creatorr.idCollectionInt === (id));


    const loadItemData = async () => {
        creater = collections.find((creatorr) => creatorr.idCollectionInt === (id));
        const updatedNfts = await getAllNFTs(creater?.idCollection);
        setNfts(updatedNfts);
    };
    
    useEffect(() => {
        document.documentElement.classList.add('light');
        loadItemData();
    }, []);


    return (
        <>
            <span className="relative rounded-full ltr:-translate-x-1/2 rtl:translate-x-1/2 -translate-y-1/2 bg-gradient-to-tl from-red-600/20 to-violet-600/20 dark:from-red-600/40 dark:to-violet-600/40"></span>
            <Navbar />
            <section className="relative bg-cover bg-scroll table w-full py-36 bg-[url('../../assets/images/bg/bg3.jpg')] bg-no-repeat">
                <div className="absolute inset-0 bg-contain bg-gradient-to-b from-transparent to-slate-900 bg-no-repeat"></div>
                    <div className="container">

                    <div className="grid grid-cols-1 mt-16 mb-24 text-center" >
                        <h3 className="md:text-sm text-sm font-xsbold text-white">Collection {collection?.name ? collection?.name :  ""}</h3>
                        <h3 className="md:text-[20px] text-[20px] font-xsbold text-white">Here you can finde the complete list of certified products within this collection, featuring handcrafted</h3>
                        <h3 className="md:text-[20px] text-[20px] font-xsbold text-white">creations and exclusive items, all meeting the highest standards of quality and authenticity!</h3>
                    </div>
                </div>
               
            </section>
            
            <section className="relative bg-cover table mt-20 w-half" >
                <DiscoverItems allData={true} pagination={true}/>
            </section>
            <Footer />
        </>
    )
}

/*

import React, { useEffect } from 'react'
import Navbar from '../../components/navbar'
import Footer from '../../components/footer'
import Select from 'react-select'
import DiscoverItems from '../../components/discover-items';
import Switcher from '../../components/switcher';
import {LuClock, LuSearch, AiOutlineAppstore, AiOutlineStar} from "../../assets/icons/vander"
const options = [
    { value: 'Art', label: 'Art' },
    { value: 'Domain', label: 'Domain Names' },
    { value: 'Music', label: 'Music' },
    { value: 'Sports', label: 'Sports' },
    { value: 'Trading', label: 'Trading Cards' },
    { value: 'Virtual', label: 'Virtual World' },
];

const buyOptions = [
    { value: 'now', label: 'Buy Now' },
    { value: 'auctions', label: 'Auctions' },
    { value: 'offers', label: 'Offers' }
]
const rateOptions = [
    { value: 'top', label: 'Top Rated' },
    { value: 'low', label: 'Lower Rated' }
]
export default function ExploreOne() {
    useEffect(() => {
        document.documentElement.classList.add('dark');
    }, []);
    return (
        <>
            <Navbar />
            <section className="relative table w-full py-36 bg-[url('../../assets/images/bg/bg1.jpg')] bg-bottom bg-no-repeat">
                <div className="absolute inset-0 bg-gradient-to-b from-transparent to-slate-900"></div>
                <div className="container">
                    <div className="grid grid-cols-1 pb-8 text-center mt-10">
                        <h3 className="md:text-3xl text-2xl md:leading-snug tracking-wide leading-snug font-medium text-white">Explore Items</h3>

                    </div>
                </div>

            </section>
            <div className="relative">
                <div className="shape absolute start-0 end-0 sm:-bottom-px -bottom-[2px] overflow-hidden z-1 text-white dark:text-slate-900">
                    <svg className="w-full h-auto" viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
                    </svg>
                </div>
            </div>

            <section className="relative md:pb-24 pb-16 -mt-16">
                <div className="container z-1">
                    <div className="grid grid-cols-1">
                        <form className="p-6 bg-white dark:bg-slate-900 rounded-xl shadow dark:shadow-gray-700">
                            <div className="registration-form text-dark text-start">
                                <div className="grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1 lg:gap-0 gap-6">
                                    <div>
                                        <label className="form-label font-medium dark:text-white">Search:</label>
                                        <div className="filter-search-form relative filter-border mt-2">
                                            <LuSearch className="icons"/>
                                            <input name="name" type="text" id="job-keyword" className="form-input w-full filter-input-box bg-gray-50 dark:bg-slate-800 border-0 focus:ring-transparent" placeholder="Search your keaywords" />
                                        </div>
                                    </div>

                                    <div>
                                        <label htmlFor="choices-catagory" className="form-label font-medium dark:text-white">Categories:</label>
                                        <div className="filter-search-form relative filter-border mt-2">
                                            <AiOutlineAppstore className="icons"/>
                                            <Select className="form-input z-2 filter-input-box bg-gray-50 dark:bg-slate-800 border-0" options={options} />

                                        </div>
                                    </div>

                                    <div>
                                        <label htmlFor="choices-min-price" className="form-label font-medium dark:text-white">Items:</label>
                                        <div className="filter-search-form relative filter-border mt-2">
                                            <LuClock className="icons text-base"/>
                                            <Select className="form-input z-2 filter-input-box bg-gray-50 dark:bg-slate-800 border-0" options={buyOptions} />

                                        </div>
                                    </div>

                                    <div>
                                        <label htmlFor="choices-max-price" className="form-label font-medium dark:text-white">Sort By:</label>
                                        <div className="filter-search-form relative mt-2">
                                            <AiOutlineStar className="icons"/>
                                            <Select className="form-input z-2 filter-input-box bg-gray-50 dark:bg-slate-800 border-0" options={rateOptions} />

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>

                <DiscoverItems allData={true} pagination={true}/>
            </section>
            <Footer />
            <Switcher />
        </>
    )
}


*/