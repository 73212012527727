import { setGlobalState, getGlobalState, setAlert } from './store/indexStore'
//import abi from './abis/TimelessNFT.json'
import abi from './abis_deploy/NFTMarketplace.json'
import abiNft from './abis_deploy/MyNFT.json'
import ethers from 'ethers'


const addressNFT = "0xAc18AFB11340B78BC789B087A02bd4B4F434bf78"
const addressMP = "0x2881557cA00Bdc9F1B21bC18a8A0B948934D0024"
var url = "https://arb1.arbitrum.io/rpc"


const { ethereum } = window 


const connectWallet = async () => {
  try {
    if (!ethereum){ 

    }else{    
      const accounts = await ethereum.request({ method: 'eth_requestAccounts' })
      if (ethereum.chainId !== '0xa4b1' || ethereum.chainId !== '0x66eed'){
        try {
          await ethereum.request({
            method: 'wallet_switchEthereumChain',
            params: [{ chainId: '0x66eed' }],
          });
        } catch (switchError) {
        // This error code indicates that the chain has not been added to MetaMask.
          if (switchError.code === 4902) {
            // Do something
          }
        }
      }
      setGlobalState('connectedAccount', accounts[0])
    }
  } catch (error) {
    reportError(error)
  }
}

let isWallectConnected = async () => {
  try {
    setGlobalState('owner', 0)      
    if (!ethereum){ return false; }else{
    const accounts = await ethereum.request({ method: 'eth_accounts' })
    if (ethereum.chainId !== '0xa4b1' || ethereum.chainId !== '0x66eed'){
      try {
        await ethereum.request({
          method: 'wallet_switchEthereumChain',
          params: [{ chainId: '0x66eed' }],
        });
      } catch (switchError) { 
      // This error code indicates that the chain has not been added to MetaMask.
        if (switchError.code === 4902) {
          // Do something
        }
      }
    }
    window.ethereum.on('chainChanged', (chainId) => {
      window.location.reload()
    })

    window.ethereum.on('accountsChanged', async () => {
      setGlobalState('connectedAccount', accounts[0])
      await isWallectConnected()
    })

    if (accounts.length) {
      setGlobalState('connectedAccount', accounts[0])
      return true;

    } else {
     
      return false;
    }
  }

  } catch (error) { 
    reportError(error)
  }
} 

const structuredCollections = (collections) => {
  return collections
    .map((collection) => ({
      owner: collection.owner.toLowerCase(),
      idCollection  : collection.idCollection,
      name: collection.name,
      collectionURI: collection.collectionURI,
      description  : collection.description,
      nftContracts  : collection.nftContracts,
      nNft  : collection.nNft,
    }))
    .reverse()
}

const structuredNfts = (nfts) => {
  return nfts
    .map((nft) => ({
      name: nft.name,
      description: nft.description,
      additionalInfo: nft.additionalInfo,
      tokenURI: nft.tokenURI,
      tokenId: nft.tokenId,
      owner: nft.owner,
      price: nft.price,
    }))
    .reverse()
}

const structuredTransaction = (trans) => {
  return trans
    .map((returnValues) => ({
      id: returnValues[0],
      collid: returnValues[1],
      price: returnValues[2],
      buyer: returnValues[3],
     
    }))
    .reverse()
}


const getAllCollectionsAndNFts = async () => {


  var ethers = require("ethers");

  var provider = new ethers.providers.JsonRpcProvider(url);
 
  const contract = new ethers.Contract( addressMP, abi.abi, provider)

  const collectionCounter = await contract.collectionCounter();


  if(collectionCounter > 0){
    const collection = await contract.getAllCollections();
    
    setGlobalState('collections', (collection[0]))

  }
  //getAllNFTs(0);

  
}

const getAllArtist = async () => {
  try {

    var provider = new ethers.providers.JsonRpcProvider(url);

    const contract = new ethers.Contract( addressMP, abi.abi, provider)

    const mpOwner = await contract.mpOwner();
    setGlobalState('owner', mpOwner[0])

    const result = await contract.getAllArtist();
    setGlobalState('artists', (result[0]))
    return result[0]
  } catch (error) {
    reportError(error)
  }
}


const getAllNFTs = async (idCollection) => {
  try {

    var provider = new ethers.providers.JsonRpcProvider(url);

    const contract = new ethers.Contract( addressMP, abi.abi, provider)
    const contractNft = new ethers.Contract( addressNFT, abiNft.abi, provider)

    const result = await contract.getNFTCollection(idCollection);
  
    return result[0];

  } catch (error) {
  }
}

const CreateArtist = async ({artistName, artistDescription, artistAdditionalDescription, metadataURI}) => {
  try {

    if (!ethereum) return 0

    const provider = new ethers.providers.Web3Provider(ethereum);

    const signer = provider.getSigner();
    
    const contract = new ethers.Contract(addressMP, abi.abi, signer)
    
    const result = await contract.createNewArtist(artistName.toString(), artistDescription.toString(), artistAdditionalDescription.toString(), metadataURI.toString());

    return result;

  } catch (error) {
    reportError(error);
  }
}


//(address newCollSC, string memory name, string memory description, string memory collectionURI, uint256 authorId, uint256 maxNfts
const CreateCollection = async ({ collectionTitle, collectionDescription, metadataURI, authorId, collectionId, maxNfts }) => {
  try {

    if (!ethereum) return 0

    const provider = new ethers.providers.Web3Provider(ethereum);
    const signer = provider.getSigner();
    
    const contract = new ethers.Contract(addressMP, abi.abi, signer)

    const result = await contract.createCollection(addressNFT, collectionTitle.toString(), collectionDescription.toString(), metadataURI.toString(), authorId, collectionId.toString(), maxNfts);

    return result;
  } catch (error) {
    reportError(error);
  }
}


const mintNFT = async (collectionId,{ collectionTitle, collectionDescription, metadataURI, isPhProduct}) => {
  try {    
    
    if (!ethereum) return 0
    const provider = new ethers.providers.Web3Provider(ethereum);
    const signer = provider.getSigner();
    
    const contract = new ethers.Contract(addressMP, abi.abi, signer)
    const account = getGlobalState('connectedAccount')
/*
uint256 collectionId,
        string memory tokenURI,
        string memory name,
        string memory description,
        address contractNft,
        uint8 _isPhProduct
*/
    const result = await contract.mintNFT(collectionId.toString(), metadataURI, collectionTitle,collectionDescription, addressNFT, isPhProduct);

    return result
  } catch (error) {
    reportError(error)
  }
}


const reportError = (error) => {
  setAlert(JSON.stringify(error), 'red')
  throw new Error('No ethereum object.')
}

export {
  getAllNFTs,
  CreateCollection,
  connectWallet,
  mintNFT,
  getAllArtist,
  CreateArtist,
  isWallectConnected,
  getAllCollectionsAndNFts,
}
