
import React, { useEffect, useState } from 'react'
import { getAllNFTs } from '../Blockchain.Services'
import {        
    useGlobalState,
    setGlobalState,
    setAlert,
  } from '../store/indexStore'
  import Select from 'react-select'


import TinySlider from "tiny-slider-react";
import '../../node_modules/tiny-slider/dist/tiny-slider.css';
import { Link } from 'react-router-dom';
import {MdOutlineArrowForward} from "../assets/icons/vander"

import {LuClock, LuSearch,AiOutlineAppstore, AiOutlineStar} from "../assets/icons/vander"

const settings = {
    lazyload: true,
    controls: true,
    mouseDrag: true,
    loop: false,
    rewind: true,
    autoplay: false,
    autoplayButtonOutput: false,
    autoplayTimeout: 300000,
    navPosition: "center",
    controlsText: ['<i class="mdi mdi-chevron-left "></i>', '<i class="mdi mdi-chevron-right"></i>'],
    nav: false,
    speed: 0,
    gutter: 0,
    responsive: {
        1025: {
            items: 4
        },

        992: {
            items: 3
        },

        767: {
            items: 2
        },
        320: {
            items: 2
        },
    },
}

export default function Collections() {
    const [collections] = useGlobalState('collections')
    const [selected, setSelected] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');
    const [searchBy, setSearchBy] = useState('id');
    let options = [
        { value: -1, label: 'All Collections ID' },
    ];

    
    let collectionsFiltered = null;
    let i = 0;
    while (i < collections.length){
        let auxArt = { value: collections[i].idCollectionInt, label: collections[i].idCollectionInt };
        options.push( auxArt)
        i++;
    }

    if(selected === null){
        collectionsFiltered = collections

    }else{
        if(selected.value === -1){
            collectionsFiltered = collections
        }else{

            collectionsFiltered = collections.filter(miembro => miembro.idCollectionInt === selected.value)

        }
    }

    const handleChangeArtist = (selectedOption) => {
        setSelected(selectedOption);
    };

    const handleSearchTermChange = (event) => {
        setSearchTerm(event.target.value);
    };

    const handleSearchByChange = (event) => {
        setSearchBy(event.target.value);
    };

    const handleSearch = () => {
        // Aquí puedes agregar la lógica para realizar la búsqueda
    };
    
    const handleSubmit = async (item) => {
        
        try {
            setGlobalState('collection', item)

            await getAllNFTs(item.idCollection)
        } catch (error) {
            setAlert('Error loading NFTs...', 'red')
         }
    }
    

    return (
        <>
            <div className="container px-36">
                
                <div className="md:flex justify-between items-center">
                    <div className="md:w-10/12 md:text-start text-start">
                        <h3 className="md:text-[20px] text-[20px] text-black"> </h3>
                    </div>
                </div>

                <div className="md:flex justify-between items-center px-0">
                    <div className="md:w-10/12 md:text-start text-start">
                        <h3 className="md:text-[20px] text-[20px] font-sans-serif text-black">CERTIFICATIONS</h3>
                    </div>
                </div>
                <div className="md:flex justify-between items-center px-0 mt-10">
                    
                    <div className="md:text-start text-start">
                        <h3 className="md:text-sm text-sm font-xsbold text-black">Find all the certified products</h3>
                    </div>
                    
                </div>
                <div className="container px-0 rounded-xl mt-10">
                            <div className="registration-form text-dark text-start">
                                <div className="grid grid-cols-1 gap-6">
                                    
                                    <div>
                                        <div className="filter-search-form relative filter-border mt-2 px-0">
                                            <AiOutlineAppstore className="icons"/>
                                            <Select className="form-input z-2 filter-input-box bg-gray-50 dark:bg-slate-800 border-0" options={options} onChange={handleChangeArtist}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                </div>
 
                

                <div className="grid relative grid-cols-1 mt-10 px-0">
                    <div className="tiny-four-icon-item">

                        <TinySlider settings={settings}>
                            {
                                collectionsFiltered.map((item, index) => (
                                    <div className="tiny-slide" key={index}>
                                        <div className="group relative overflow-hidden bg-white dark:bg-slate-900 rounded-lg p-3 shadow dark:shadow-gray-800 ease-in-out duration-500 m-2">
                               
                                            <div className="relative">
                                                <i className="mdi mdi-check-decagram text-emerald-600 text-2xl absolute -top-3 -end-2"></i>
                                                <img
                                                    className="rounded-lg" alt=""
                                                    src={item.collectionURI}
                                                />
                                            </div>
                                            <div className="relative p-4 -mt-26">
                                                <div className="">
                                                    <Link to={"/explore-one/"+item.idCollectionInt} onClick={() => handleSubmit(item)} className="text-black block text-[14px] mt-1">Id {item.idCollectionInt}: {item.name}</Link>
                                                    <span className="text-black block text-[14px] mt-1">{item.nNft.toString()} items </span>
                                                    <div className="flex items-center">
                                                        <div className="border-x w-8 h-0.5 mt-3"></div>
                                                        <Link to={"/explore-one/"+item.idCollectionInt}  onClick={() => handleSubmit(item)} className="mt-3 font-semibold block text-[13px] text-orange">
                                                            <span className="border-l border-black pl-4 ml-4">Have a look</span>
                                                        </Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>                                            
                                    </div>
                                ))
                            }
                        </TinySlider>

                    </div>
                </div>

                <div className="grid grid-cols-1 mt-6 md:hidden">
                    <div className="text-center">
                        <Link to="/collections" className="btn btn-link text-[16px] font-medium hover:text-violet-600 after:bg-violet-600 duration-500 ease-in-out inline-flex items-center">See More <MdOutlineArrowForward className="text-sm ms-1"/></Link>
                    </div>
                </div>
            </div>
        </>
    )
}
