import React from 'react'
import { Link } from 'react-router-dom';
import {LiaMinusSolid} from "../assets/icons/vander"

export default function GetTouch() {
    return (
        <div className="container mt-46 px-12">
            <div className="grid grid-cols-1 text-center">
                <div class="flex justify-left">
                    <div className="md:text-start text-center">
                        <h3 className="md:text-smm text-[16px] font-xsbold text-black">Have Question? Get in touch!</h3>
                    </div>        
                </div>


                <div class="flex justify-center mt-16">
                    <div className="md:text-start text-center">
                        <h3 className="md:text-smmm text-[16px] font-inter text-black">We are Gaudí Knowledge Association, committed to linking exceptional artists with their devoted fans and certified product collectors!</h3>
                    </div>        
                </div>
                
                <div class="flex justify-left">
                    <div className="mt-6">
                        <Link to="https://www.thegaudiacademia.com/contact/" className="btnC btnC-sm bg-violet-600 hover:bg-orange border-violet-600 hover:border-violet-700 text-white rounded-full me-2 mt-2 inline-flex items-center">
                            <h3 className='md:text-sm text-[16px]'/>  Contact us now
                        </Link>                    
                    </div>
                </div>
            </div>
        </div>
    )
}

/*

               < div class="flex justify-center mt-8 mb-8">
                    <div class="divider h-28 border-l border-gray-400"></div>
                </div>
*/