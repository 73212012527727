import React, { useEffect } from 'react'
import single from '../../assets/images/blog/single.jpg';
import image from '../../assets/images/GKA-white.png';
import imageBlack from '../../assets/images/GKA-black.png';
import NavbarBlack from '../../components/navbar-black'
import Footer from '../../components/footer';
import Switcher from '../../components/switcher';
import { Link, useParams } from 'react-router-dom';
import DiscoverItems from '../../components/discover-items';
import {        
    useGlobalState,
    setGlobalState,
  } from '../../store/indexStore'
  
import {getAllArtist } from '../../Blockchain.Services'


export default function CreatorProfile() {
    const params = useParams();
    const [artists,setArtists] = useGlobalState('artists')
    const [owner] = useGlobalState('owner')

    const id = params.id
    const creater = artists.find((creatorr) => creatorr?.idArtist.toString() === (id));

    const loadItemData = async () => {
        const artistsAux = await getAllArtist();
        setArtists(artistsAux);
    };
    
    useEffect(() => {
        document.documentElement.classList.add('light');
        loadItemData();
    }, []);



    const loadFile = (event) => {
        const image = document.getElementById(event.target.name);
        image.src = URL.createObjectURL(event.target.files[0]);
    };

    return (
        <>
            <span className="relative rounded-full ltr:-translate-x-1/2 rtl:translate-x-1/2 -translate-y-1/2 bg-gradient-to-tl from-red-600/20 to-violet-600/20 dark:from-red-600/40 dark:to-violet-600/40"></span>
            <NavbarBlack />
            <section className="relative bg-cover bg-scroll table w-full py-36 bg-[url('../../assets/images/bg/bg4.jpg')] bg-no-repeat">
                <div className="absolute inset-0 bg-contain bg-gradient-to-b from-transparent to-slate-900 bg-no-repeat"></div>
                    <div className="container">

                    <div className="grid grid-cols-1 mt-16 mb-24 text-center" >
                        <h3 className="md:text-sm text-sl font-xsbold text-white"> {creater?.name ?  creater?.name : "Gaudí Knowledge"}</h3>
                        <h3 className="md:text-[20px] text-[20px] font-xsbold text-white">Discover the Talented Artists and Creators Behind Our Collection of Handcrafted and Exclusive Works,</h3>
                        <h3 className="md:text-[20px] text-[20px] font-xsbold text-white">All Meeting the Highest Standards of Quality and Authenticity!</h3>
                    </div>
                </div>
               
            </section>
            <section className="relative pt-28 md:pb-24 pb-16 mt-14 ">
                <div className="small_xxls_container">

                    <div className="grid lg:grid-cols-12 md:grid-cols-2 grid-cols-1 gap-[30px]">
                        
                    <div className="lg:col-span-5 md:order-1 order-1">
                        <div className="group border-gray-100 dark:border-gray-800 hover:shadow-gray-700 transition-all duration-500 hover:-mt-2 h-fit">

                            {creater != null ? (
                                    
                                    <div  >
                                        <img src={creater?.avatar ? creater?.avatar : imageBlack} className="rounded-md shadow w-full dark:shadow-gray-700" alt="" />
                                        <span className="mdi mdi-check-circle font-inter text-emerald-600"></span>

                                        <div className="rounded-md mt-[30px] p-6">
                                            <div>
                                                <span className="font-medium font-inter text-green block mb-1">Contract Address</span>
                                                <Link to={"https://arbiscan.io/address/"+process.env.REACT_APP_ADDR_NFT} className="text-[16px] font-inter text-black block">{process.env.REACT_APP_ADDR_NFT.slice(0,10)}....</Link>
                                            </div>

                                        
                                            <div className="mt-4">
                                                <span className="font-medium text-green block mb-1 font-inter">Artist ID</span>
                                                <span className="text-[16px] block font-inter text-black">{creater?.idArtist.toString() ?  creater?.idArtist.toString() : "Creator Id: " + id +" not found"}</span>
                                            </div>

                                        </div>
                                    </div>

                                
                            ) : (
                                <div className="flex justify-center items-center mt-3 mb-3">
                                    <div className="spinner"></div>
                                </div>
                            )}
                        </div>
                    
                    </div>
                        <div className="lg:col-span-7 lg:ms-8 md:order-2 order-2">
                         
                            <span className="md:text-smm sm:text-smm lg:text-smm xl:text-smm text-black block mt-6">Artoy creator Series!</span>

                            <p className="text-black mt-8 font-inter">Name: {creater?.name ? creater?.name : "Creator Id: " + id +" not found"}</p>
                            <p className="text-black mt-4 font-inter">Description: {creater?.description ?  creater?.description : "Creator Id: " + id +" not found"}</p>
                            <p className="text-black mt-4 font-inter">Aditional description: {creater?.additionalInfo ? creater?.additionalInfo : "Creator Id: " + id +" not found"}</p>
                         
                        </div>
                    </div>
                </div>
            </section>
            

            <Footer />
            </>
    )
}

/*


            <section className="container">

                <div className="md:flex justify-center">
                    <div className="md:w-full">
                        <div className="relative -mt-[60px] text-center">
                            <div className="group profile-pic w-[112px] mx-auto">
                                <input id="pro-img" name="profile-image" type="file" className="hidden" onChange={loadFile} />
                                <div>
                                    <div className="relative h-28 w-28 mx-auto rounded-full shadow dark:shadow-gray-800 ring-4 ring-slate-50 dark:ring-slate-800 overflow-hidden">
                                        <img src={creater?.avatar ? creater?.avatar : image} className="rounded-full" id="profile-image" alt="" />
                                        <div className="absolute inset-0 group-hover:bg-slate-900/40 transition duration-500"></div>
                                        <label className="absolute inset-0 cursor-pointer" htmlFor="pro-img"></label>
                                    </div>
                                </div>
                            </div>
        
                            <div className="mt-6">
                                <h5 className="text-black mt-4 font-inter">{creater?.name} <i className="mdi mdi-check-decagram text-emerald-600 align-middle text-lg"></i></h5>
                                <h5 className="text-black mt-4 font-inter">{creater?.description}</h5>
                                <h5 className="text-black mt-4 font-inter">{creater?.additionalInfo}</h5>
                                <p className="text-black mt-4 font-inter">Created by <Link to={`https://goerli.arbiscan.io/address/${owner}`} className="text-violet-600 font-semibold">{owner}</Link></p>
                            </div>
                        </div>
                    </div>
                </div>
           
            </section>
*/