import React, { useEffect, useState } from 'react'
import Creator from '../../components/creator';
import Collections from '../../components/collections';
import GetTouch from '../../components/get-touch';
import Footer from '../../components/footer';
import Navbar from '../../components/navbar';
import left_img from '../../assets/images/bg/1.png';
import rigth_img from '../../assets/images/bg/2.png';
import { LuSearch} from "../../assets/icons/vander"

import {        
    useGlobalState,
    setGlobalState,
    setAlert,
  } from '../../store/indexStore'


export default function Index() {

    const [selected, setSelected] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');
    const [searchBy, setSearchBy] = useState('id');
    let options = [
        { value: -1, label: 'All artists' },
    ];

    let optionsColl = [
        { value: -1, label: 'All Collections' },
    ];

    const [collections] = useGlobalState('collections')
    const [artists] = useGlobalState('artists')
    let collectionsFiltered;

    let i = 0;
    while (i < artists.length){
        let auxArt = { value: artists[i].idArtist, label: artists[i].name };
        options.push( auxArt)
        i++;
    }
    i = 0;
    while (i < collections.length){
        let auxArt = { value: collections[i]?.idCollection, label: collections[i].name };
        optionsColl.push( auxArt)
        i++;
    }
    if(selected === null){
        collectionsFiltered = collections

    }else{
        if(selected.value === -1){
            collectionsFiltered = collections
        }else{

            collectionsFiltered = collections.filter(miembro => miembro.authorId.toNumber() === selected.value.toNumber())
        }
    }

    const handleSearchByChange = (event) => {
        setSearchBy(event.target.value);
      };
    //ADD read collections!
    
    useEffect(() => {
        document.documentElement.classList.add('light');
        document.documentElement.className = 'light'
        document.body.classList.add('font-urbanist', 'text-base', 'text-white');
       // getAllCollections(); 
    }, []);

    return (
        <>
            <Navbar />
            <section className="relative bg-cover bg-scroll table w-full py-36 bg-[url('../../assets/images/bg/bg2.jpg')] bg-no-repeat">
                <div className="absolute inset-0 bg-contain bg-gradient-to-b from-transparent to-slate-900 bg-no-repeat"></div>
                    <div className="container">

                    <div className="grid grid-cols-1 mt-16 mb-24 text-center" >
                        <h3 className="md:text-sm text-sm font-xsbold text-white">Gaudí - Inspired design</h3>
                        <h3 className="md:text-sm text-sm font-xsbold text-white">authenticity registry</h3>
                    </div>
                </div>
               


            </section>
            
            <section className="relative bg-cover table w-screen md:py-36 py-0 bg-[url('../../assets/images/bg/bg1.jpg')] bg-center bg-no-repeat" >
                <div className="big_container">
                    <Collections/>
                </div>    
            
            </section>

            <section className="relative md:py-1 py-16">
                <div className="big_container px-51">
                    <Creator title="Top Artist-Certified Products" 
                        description="Explore our curated list of top artist-certified products, featuring handcrafted creations and exclusive items, all meeting the highest standards of quality and authenticity!" />
                    <GetTouch />
                </div>
            </section>
            <Footer />
        </>
    )
}
