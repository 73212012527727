import './App.css';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import Index from './pages/index/index';
import { useEffect } from 'react';
import React from 'react';
import BecomeCreator from './pages/creator/become-creator';
import { Analytics } from '@vercel/analytics/react';

import "@fontsource/tangerine"; // Defaults to weight 
import '@fontsource/source-serif-pro';
import '@fontsource-variable/source-serif-4';

//  resolved "https://registry.npmjs.org/ethereumjs-abi/-/ethereumjs-abi-0.6.8.tgz"


import CreatorProfile from './pages/creator/creator-profile';
import ItemDetail from './pages/explore/item-detail';
import ExploreOne from './pages/explore/explore-one';
import Collections from './pages/explore/collections';
//import CollectionsBuildings from './pages/explore/collections_buildings';
import UploadWork from './pages/explore/upload-work';
import UploadNft from './pages/explore/upload-nft';
import ScrollToTop from './components/scroll-top';
import { getAllArtist, getAllCollectionsAndNFts,isWallectConnected } from './Blockchain.Services'

import {connect} from "./lib/ipfs.js"

function App() {

  useEffect (() => {
    document.documentElement.setAttribute("dir", "ltr");
    document.documentElement.classList.add('light');
    
   
    document.body.classList.add('font-urbanist', 'text-base', 'text-white', 'dark:text-white', 'dark:bg-slate-900');
    const execute = async ( ) => {
    //  await connect()

     // await isWallectConnected();       

      await getAllCollectionsAndNFts();     
      await getAllArtist();     

    };        
    execute ( );
  }, [ ]);
  
  return (
    <BrowserRouter>
      <Analytics />
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Navigate to="/index" replace />} />

        <Route path="/index" element={<Index />} />
        <Route path='/explore-one' element={<Index />} />
        <Route path='/explore-one/:id' element={<ExploreOne />} />
        <Route path='/collections' element={<Collections />} />
        <Route path='/upload-work' element={<UploadWork />} />
        <Route path='/upload-nft' element={<UploadNft />} />
 
        <Route path='/item-detail' element={<ItemDetail />} />
        <Route path='/item-detail/:id' element={<Index />} />
        <Route path='/creator-profile/:id' element={<CreatorProfile />} />
        <Route path='/creator-profile' element={<Index />} />
        <Route path='/become-creator' element={<BecomeCreator />} />

      </Routes>
    </BrowserRouter>
  );
}

export default App;
