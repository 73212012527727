import React from 'react';
import { Link } from 'react-router-dom';
import TinySlider from "tiny-slider-react";

import {FiUserPlus} from "../assets/icons/vander"
import {        
    useGlobalState,
  } from '../store/indexStore'
  
  const settings = {
    lazyload: true,
    controls: true,
    container: "#center",

    mouseDrag: true,
    loop: true,
    rewind: true,
    autoplay: true,
    autoplayButtonOutput: false,
    autoplayTimeout: 10000,
    navPosition: "center",
    controlsText: ['<i class="mdi mdi-chevron-left "></i>', '<i class="mdi mdi-chevron-right"></i>'],
    nav: false,
    speed: 4000,
    gutter: 0,
    responsive: {
        1525: {
            items: 5
        },

        992: {
            items: 4
        },

        767: {
            items: 3
        },
        320: {
            items: 2
        },
    },
}
export default function Creator(props) {
    const [artists] = useGlobalState('artists')
 
    const handleSubmit = async (item) => {
        
        try {
      //      setGlobalState('collection', item)

            //await getAllNFTs(item.idCollection)
        } catch (error) {
            console.log('Error loading NFTs: ', error)
      //      setAlert('Error loading NFTs...', 'red')
        }
    }
    
    
    const { title, description } = props;

    return (
        <div className="container">
            <div className="md:flex justify-between items-center">
                <div className="md:w-10/12 md:text-start text-center">
                   <h3 className="md:text-sm font-xsbold text-black">{title}</h3>
                </div>
            </div>

            <div className="md:flex justify-between items-center mt-5">
                <div className="md:text-start text-left">
                    <h3 className="md:text-smmm font-sans-serif text-[16px] text-black">{description}</h3>
                </div>        
            </div>
            <div className="grid grid-cols-1 mt-10">
                    <div className="tiny-four-icon-item">

                        <TinySlider settings={settings}>
                        {
                            artists.map((item, index) => (
                                <div className="tiny-slide" key={index}>
                                    <div className="group relative overflow-hidden rounded-md p-3 shadow ease-in-out duration-500 m-2">
                                        <div className="relative inline-block">
                                            <Link to={`/creator-profile/${item.idArtist}`}>
                                                <img src={item.avatar} className="rounded-lg" alt="" />
                                            </Link>
                                            <i className="mdi mdi-check-decagram text-emerald-600 text-lg absolute -top-2 -end-2"></i>
                                            
                                            <span className="text-black font-semibold block text-20 mt-2">{item.description.split(' ').slice(0, 8).join(' ')}...</span>

                                            <Link to={`/creator-profile/${item.idArtist}`} className="text-orange block text-[20px] mt-2">@{item.name}</Link>
                                        </div>
                                    </div>
                                </div>
                            ))
                        }

                        </TinySlider>

                    </div>
                </div>
        </div>
    )
}
